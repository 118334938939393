import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Про Tefix
			</title>
			<meta name={"description"} content={"Відкрий свою силу, прийми свою подорож - шлях Tefix"} />
			<meta property={"og:title"} content={"Про нас | Про Tefix"} />
			<meta property={"og:description"} content={"Відкрий свою силу, прийми свою подорож - шлях Tefix"} />
			<meta property={"og:image"} content={"https://arboratefix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://arboraarboratefix.com/img/20956.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arboraarboratefix.com/img/20956.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arboraarboratefix.com/img/20956.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arboraarboratefix.com/img/20956.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arboraarboratefix.com/img/20956.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arboraarboratefix.com/img/20956.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" md-padding="80px 0 90px 0" quarkly-title="Headline-1">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://arboratefix.com/img/7.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					object-position="50% 0"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					margin="0px 0px 0px 15px"
					md-margin="0px 0px 0px 0"
					md-width="100%"
				>
					<Text margin="0px 0px 20px 0px" letter-spacing="1px" font="--base">
					Про нас та послуги
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 500 64px/1.2 --fontFamily-serifGeorgia" lg-font="normal 500 56px/1.2 --fontFamily-serifGeorgia" md-font="normal 500 42px/1.2 --fontFamily-serifGeorgia">
					Відчуйте Прем'єр Сервіс
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base">
					У Tefix ми розуміємо важливість надійного та ефективного автомобіля. Наш комплексний спектр послуг розроблено для задоволення всіх ваших автомобільних потреб, забезпечуючи найкращу роботу вашого автомобіля. Від планового технічного обслуговування до складного ремонту, наші досвідчені техніки готові впоратися з усім.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Наша команда експертів
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Наші механіки не просто працівники, вони автолюбителі та експерти своєї справи. Завдяки ретельному процесу відбору та постійному навчанню ми гарантуємо, що ваш автомобіль у вправних руках. Кожен учасник прагне підтримувати найвищі стандарти якості обслуговування та догляду за автомобілями.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Регулярне технічне обслуговування
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Заміна масла: Забезпечте безперебійну роботу двигуна завдяки заміні високоякісного масла та фільтрів.
					<br/><br/>
Шиномонтажні послуги: від обертання до балансування, наші шинні послуги допомагають продовжити термін служби ваших шин і підвищити безпеку.
					<br/><br/>
Перевірка гальм: регулярні перевірки та технічне обслуговування, щоб ваша гальмівна система залишалася чутливою та надійною.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Розширена діагностика
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Діагностика двигуна: використовуйте новітні технології для швидкого виявлення проблем двигуна та запобігання проблемам у майбутньому.
					<br/><br/>
Тестування електричних систем: комплексні перевірки для забезпечення належної роботи всіх електричних компонентів.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Ремонтні послуги
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ремонт трансмісій: професійний ремонт і технічне обслуговування автоматичних і механічних коробок передач.
					<br/><br/>
Робота з підвіскою: покращте комфорт їзди та керованість за допомогою наших послуг з ремонту підвіски.
					<br/><br/>
Кондиціонер та опалення: забезпечте роботу клімат-контролю вашого автомобіля цілий рік за допомогою наших послуг HVAC.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="CTA/Lead/Form-5">
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="center"
				sm-flex-direction="column"
				sm-align-items="center"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 30px 20px 30px"
					font="normal 700 52px/1.2 --fontFamily-sans"
					color="--dark"
					text-align="center"
					sm-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
				>
					Приведемо ваше авто в ідеальний стан
				</Text>
			</Box>
			<Text
				margin="0px 250px 70px 250px"
				text-align="center"
				font="normal 400 18px/1.5 --fontFamily-sansHelvetica"
				color="#697075"
				grid-template-columns="repeat3, 1fr)"
				lg-margin="0px 80px 70px 80px"
				md-margin="0px 0 70px 0"
			>
				Відвідайте Tefix і на власні очі дізнайтеся, як ми можемо покращити продуктивність і довговічність вашого автомобіля. Ваш автомобіль заслуговує на професійний догляд, і ми тут, щоб його забезпечити.
			</Text>
			<Box
				min-width="10px"
				min-height="10px"
				display="grid"
				align-items="center"
				align-self="center"
				lg-align-self="auto"
			>
				<Button
					margin="0px 15px 0px 0px"
					padding="12px 28px 12px 28px"
					background="#023096"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#023096"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
				>
					Контакти
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});